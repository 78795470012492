
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
} from "vue";
import { useRoute } from "vue-router";
import { ApiSalesOrders, ApiMerchant, ApiBase } from "@/core/api";
import { hideModal } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { FulfillmentSource } from "@/core/directive/type/fulfillmentOrder";
import { getFulfillmentSourceRadio } from "@/core/directive/function/fulfillmentOrder";
import { merchantChannelOption } from "@/core/directive/interface/fulfillmentOrder";
import { ElTable } from "element-plus";
import { SalesOrderType } from "@/core/directive/type/salesOrder";
import _ from "lodash";
import CommonChannelOption from "@/components/layout/CommonChannelOption.vue";
import { removeReadonly } from "@/core/directive/function/common";

interface User {
  quantity: number;
}
interface SubmitTable {
  sku_identifier: string;
  max_qty_remained: number | string;
  quantity: number;
  items_id: number | string;
  base_unit_sales_order_price: string;
}

export default defineComponent({
  name: "add-fulfillment-overview",
  components: { CommonChannelOption },
  props: {
    sale_order_type: {
      type: String,
      default: "",
    },
    order_number: {
      type: String,
      default: "",
    },
  },
  emits: ["update-list"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const currentInstance: any = getCurrentInstance();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const addFulfillmentOverview = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    // const channelOptions = ref([]);
    const formData = ref({
      external_order_number: "",
      sale_order_number: "",
      merchant_profile: {
        merchant: "",
        merchant_country: "",
        channel: "",
        channel_key: "",
      },
      inventory_owner: "",
      inventory_status: "",
      warehouse_key: "",
      fulfilled_items: ref<SubmitTable[]>([]),
      merchant: "",
      fulfillment_type: FulfillmentSource.Merchant,
      warehouse: "",
      inventory_type: "",
    });

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const rules = ref({
      merchant: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      "merchant_profile.channel": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      "merchant_profile.channel_key": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      inventory_type: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      warehouse_key: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const options = ref({
      FulfillmentSource: FulfillmentSource,
      merchant_profile_channel: [] as any[],
      merchant: [] as any[],
      merchantDefault: [],
      merchant_map: new Map([]),
      warehouse: [] as any[],
      inventory_type: [],
      fulfillment_type: getFulfillmentSourceRadio(t),
    });

    const cancelReadonly = (async) => {
      removeReadonly(async, currentInstance);
    };

    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    //克隆表格数据
    const cloneTableData = ref<SubmitTable[]>([]);
    const tableData = ref([]);
    // 选中的表格数据
    const submitTableData = ref<SubmitTable[]>([]);

    const handleSelectionChange = (selection: User[]) => {
      let Arr: any = [],
        fulfilled_items: any = [];
      submitTableData.value = [];
      let warehouseArr: any = [],
        warehouse_id: any = [],
        warehouse_owner = new Map([]),
        warehouse_status = new Map([]);
      let index = 0;
      selection.map((item: any) => {
        multipleTableRef.value!.setCurrentRow(item);
        Arr.push(item.index);
        fulfilled_items.push(cloneTableData.value[item.index]);
        item.inventory.map((inventory) => {
          if (warehouse_id.indexOf(inventory.id) > -1) {
            const owner: any = warehouse_owner.get(inventory.id);
            const status: any = warehouse_status.get(inventory.id);
            if (
              owner.indexOf(inventory.owner) === -1 ||
              status.indexOf(inventory.status) === -1
            ) {
              owner.push(inventory.owner);
              status.push(inventory.status);
              warehouse_owner.set(inventory.id, owner);
              warehouse_status.set(inventory.id, status);
              warehouseArr.push({
                ...inventory,
                key: index,
              });
              index++;
            }
          } else {
            warehouse_id.push(inventory.id);
            warehouse_owner.set(inventory.id, [inventory.owner]);
            warehouse_status.set(inventory.id, [inventory.status]);
            warehouseArr.push({
              ...inventory,
              key: index,
            });
            index++;
          }
        });
      });
      formData.value.fulfilled_items = fulfilled_items;
      options.value.warehouse = warehouseArr;
      if (formData.value.fulfillment_type === FulfillmentSource.PreStock) {
        // if (submitTableData.value.length > 1) {
        //   formData.value.fulfillment_type = FulfillmentSource.Merchant;
        //   options.value.merchant = options.value.merchantDefault;
        // }
        resetData();
      }
    };

    const getContactInfo = () => {
      loading.value = true;
      ApiSalesOrders.getSalesOrderItems({
        order_number: props.order_number,
      })
        .then(({ data }) => {
          loading.value = false;
          cloneTableData.value = [];
          submitTableData.value = [];
          tableData.value = data.data.items;
          tableData.value.forEach((item: any, index) => {
            item.index = index;
          });
          data.data.items.forEach((item: any) => {
            cloneTableData.value.push({
              sku_identifier: item.sku_identifier,
              max_qty_remained: item.qty_remained,
              quantity: item.qty_remained,
              items_id: item.id,
              base_unit_sales_order_price: item.base_unit_sales_order_price,
            });
          });
          formData.value.external_order_number =
            data.data.external_order_number;
          formData.value.external_order_number =
            data.data.external_order_number;
          formData.value.sale_order_number = data.data.order_number;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getFulfillmentOrderItemsSpotInventory = (e, remake) => {
      if (remake && e !== "" && e !== undefined && e !== null) {
        formData.value.warehouse = options.value.warehouse[e].id;
      }
      if (
        formData.value.fulfilled_items.length > 0 &&
        formData.value.warehouse_key !== "" &&
        formData.value.inventory_type !== "" &&
        formData.value.fulfillment_type === FulfillmentSource.PreStock
      ) {
        const sku_identifier = [
          formData.value.fulfilled_items[0].sku_identifier,
        ];
        options.value.merchant_profile_channel = [];
        options.value.merchant = [];
        formData.value.merchant = "";
        formData.value.merchant_profile.channel = "";
        formData.value.merchant_profile.channel_key = "";
        formData.value.merchant_profile.merchant_country = "";
        formData.value.inventory_owner = "";
        formData.value.inventory_status = "";
        let warehouseData: any;
        options.value.warehouse.map((item) => {
          if (item.key == formData.value.warehouse_key) {
            warehouseData = item;
          }
        });
        ApiSalesOrders.getFulfillmentOrderItemsSpotInventory({
          sku_identifier: sku_identifier,
          warehouse_id: formData.value.warehouse,
          inventory_type: formData.value.inventory_type,
          status: warehouseData?.status,
          owner: warehouseData?.owner,
          sale_order_number: formData.value.sale_order_number,
        })
          .then(({ data }) => {
            if (data.code == 0) {
              options.value.merchant = data.data;
              options.value.merchant_map.clear();
              data.data.map((item) => {
                options.value.merchant_map.set(item.id, item);
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    const getSalesChannelsData = async (id?, remake?) => {
      if (remake) {
        formData.value.merchant_profile.channel = "";
        formData.value.merchant_profile.channel_key = "";
        options.value.merchant_profile_channel = [];
      }
      if (formData.value.fulfillment_type === FulfillmentSource.Merchant) {
        ApiMerchant.getSalesChannelsData({
          max_item: "all",
          merchant_id: id,
          status: "10",
        })
          .then(({ data }) => {
            if (data.code == 0) {
              data.data.forEach((item, index) => {
                item.key = index;
              });
              options.value.merchant_profile_channel = data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const channel: any =
          (options.value.merchant_map.get(id) as merchantChannelOption)
            ?.channel ?? [];
        channel.forEach((item, index) => {
          item.key = index;
        });
        options.value.merchant_profile_channel = channel;
      }
    };

    const channelChange = (e) => {
      if (e !== "" && e !== undefined && e !== null) {
        formData.value.merchant_profile.channel =
          options.value.merchant_profile_channel[e].id;
      }
    };

    const isDisabledSubmit = computed(() => {
      const fulfilled_items: any[] = formData.value.fulfilled_items;
      if (fulfilled_items.length > 0) {
        let sku_identifier: string[] = [],
          checkQuantityIsSure = false;
        fulfilled_items.forEach((item) => {
          if (sku_identifier.indexOf(item.sku_identifier) == -1) {
            sku_identifier.push(item.sku_identifier);
          }
          if (
            Number(item.quantity) <= 0 ||
            item.quantity === "" ||
            Number(item.quantity) > item.max_qty_remained
          ) {
            checkQuantityIsSure = true;
          }
        });
        // if (
        //   formData.value.fulfillment_type === FulfillmentSource.Merchant &&
        //   sku_identifier.length > 1
        // ) {
        //   return true;
        // } else
        if (checkQuantityIsSure) {
          return true;
        }
        if (
          props.sale_order_type != String(SalesOrderType.ServiceOrder) &&
          fulfilled_items.length > 1
        ) {
          return true;
        }
        return false;
      }
      return true;
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          if (formData.value.fulfillment_type === FulfillmentSource.Merchant) {
            Object.keys(formData.value.merchant).forEach((key) => {
              if (key == "value") {
                formData.value.merchant_profile.merchant =
                  formData.value.merchant[key];
              } else if (key == "entity_data") {
                formData.value.merchant_profile.merchant_country =
                  formData.value.merchant[key].country_iso_2;
              } else if (key == "id") {
                formData.value.merchant_profile.merchant =
                  formData.value.merchant[key];
              } else if (key == "merchant_country") {
                formData.value.merchant_profile.merchant_country =
                  formData.value.merchant[key];
              }
            });
          } else {
            const channel_key = formData.value.merchant_profile.channel_key;
            options.value.merchant.map((item) => {
              if (item.id == formData.value.merchant) {
                formData.value.merchant_profile.merchant_country =
                  item.merchant_country;
              }
            });
            formData.value.merchant_profile.merchant = formData.value.merchant;
            formData.value.inventory_owner =
              options.value.merchant_profile_channel[
                channel_key
              ]?.inventory_owner;
            formData.value.inventory_status =
              options.value.merchant_profile_channel[
                channel_key
              ]?.inventory_status;
          }
          ApiSalesOrders.addFulfillmentOrder(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                  hideModal(addFulfillmentOverview.value);
                  emit("update-list");
                });
              } else {
                formData.value.merchant_profile.merchant_country = "";
                formData.value.merchant_profile.merchant = "";
                formData.value.inventory_owner = "";
                formData.value.inventory_status = "";
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const fulfillmentTypeChange = () => {
      resetData();
      options.value.merchant = [];
    };

    const getMerchantSourceData = async (value, id?) => {
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData({
        ...params,
        options: { include_fields: ["name", "country_iso_2", "address"] },
      });
      if (data.code == 0) {
        options.value.merchant = data.data;
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["sku_inventory_type"],
      });
      if (data.code == 0) {
        options.value.inventory_type = data.data.sku_inventory_type.items;
      }
    };

    const getFormInfo = async () => {
      // loading.value = true;
      Promise.all([getTaggingData(), getMerchantSourceData("")])
        .then(() => {
          // loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const modalShowListener = (modalEl: HTMLElement | null, callback): void => {
      if (!modalEl) {
        return;
      }
      modalEl.addEventListener("show.bs.modal", callback);
    };

    const resetData = () => {
      formData.value.merchant = "";
      formData.value.merchant_profile.merchant = "";
      formData.value.merchant_profile.channel = "";
      formData.value.merchant_profile.channel_key = "";
      options.value.merchant_profile_channel = [];
      formData.value.warehouse = "";
      formData.value.warehouse_key = "";
      formData.value.inventory_type = "";
      formData.value.inventory_owner = "";
      formData.value.inventory_status = "";
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      resetData();
    };

    onMounted(() => {
      modalShowListener(addFulfillmentOverview.value, () => {
        getContactInfo();
      });
      getFormInfo();
      cancelReadonly(false);
    });

    return {
      t,
      loading,
      formData,
      submitButton,
      formRef,
      multipleTableRef,
      options,
      rules,
      tableData,
      addFulfillmentOverview,
      cancelReadonly,
      handleSelectionChange,
      getContactInfo,
      submit,
      getSalesChannelsData,
      submitTableData,
      cloneTableData,
      resetForm,
      modalShowListener,
      fulfillmentTypeChange,
      getFulfillmentOrderItemsSpotInventory,
      isDisabledSubmit,
      channelChange,
      searchMerchantSourceItems,
    };
  },
});
