
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useAttrs,
  watch,
} from "vue";
import { MBDatatable } from "magicbean-saas-common";
import { useRoute, useRouter } from "vue-router";
import { ApiSalesOrders } from "@/core/api";
import addFulfillmentOverview from "@/views/sales-order/order-overview/AddFulfillmentOverview.vue";
import { useI18n } from "vue-i18n";
import { FilterOption, SortOption } from "@/core/directive/interface/common";
import { NumberOrString } from "@/core/directive/type/common";
import { getFulfillmentStatus } from "@/core/directive/function/fulfillmentOrder";
import { FulfillmentOrderStatus } from "@/core/directive/type/fulfillmentOrder";
import { SalesOrderState } from "@/core/directive/type/salesOrder";
import { SalesOrderOverview } from "@/core/directive/interface/salesOrder";
import {
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";

interface Merchant {
  order_number: string;
  order_date: string;
  order_type: number;
  status: string;
  sales_channel: string;
  bill_to: string;
  ship_to: string;
  grand_total_base: string;
  grand_total_purchase: string;
}

export default defineComponent({
  name: "fulfillment-overview",
  components: {
    MBDatatable,
    addFulfillmentOverview,
  },
  setup() {
    const { t } = useI18n();
    const attrs = useAttrs(); // 接收父路由参数

    const route = useRoute();
    const router = useRouter();

    const loading = ref(true);
    const tableData = ref<Array<Merchant>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const isDisabledAdd = ref<boolean>(true);

    const filterOptions = ref<Array<FilterOption>>([
      {
        field: "sale_order_number",
        value: route.params.id,
      },
    ]);

    const sortOptions = ref<Array<SortOption>>([
      { field: "id", direction: "desc" },
    ]);
    const tableHeader = ref([
      {
        name: t("fulfillmentOrder.fulfillmentOrder"),
        key: "fulfillment_order_number",
        sortable: true,
      },
      {
        name: t("fulfillmentOrder.createdDate"),
        key: "created_at",
        sortable: true,
      },
      {
        name: t("fulfillmentOrder.orderStatus"),
        key: "status",
        sortable: true,
      },
      {
        name: t("fulfillmentOrder.salesOrderIdentifier"),
        key: "sale_order_number",
        sortable: true,
      },
      {
        name: t("fulfillmentOrder.estimatedDelayDays"),
        key: "estimated_delay_days",
        sortable: true,
      },
      {
        name: t("fulfillmentOrder.merchantName"),
        key: "merchant",
        sortable: true,
      },
      {
        name: t("fulfillmentOrder.channel"),
        key: "channel",
        sortable: true,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const options = ref({
      FulfillmentOrderStatus: FulfillmentOrderStatus,
      saleOrderType: "",
      saleOrderNumber: "",
    });

    watch(
      () => attrs.loading,
      (newValue) => {
        loading.value = newValue as boolean;
        const data = attrs.detailData as SalesOrderOverview;
        init(data);
      }
    );

    const cancelId = ref(0);

    onMounted(() => {
      setModuleBCN(t, route, router);
      if (!attrs.loading) {
        const data = attrs.detailData as SalesOrderOverview;
        init(data);
      }
    });

    const getCompanyList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;

      ApiSalesOrders.getFulfillmentOrderList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getCompanyData = async () => {
      loading.value = true;
      const { data } = await ApiSalesOrders.getCompanyInfo({
        order_id: route.params.id,
      });
      loading.value = false;
      if (data.code == 0) {
        if (data.data.sales_order_identifier) {
          setDisabled(data.data.payment_information.order_state);
          options.value.saleOrderType =
            data.data.sales_order_identifier.order_type;
          options.value.saleOrderNumber =
            data.data.sales_order_identifier.order_number;
          filterOptions.value = [
            {
              field: "sale_order_number",
              value: data.data.sales_order_identifier.order_number,
            },
          ];
          getCompanyList(
            currentPage.value,
            pageSize.value,
            search.value,
            filterOptions.value,
            sortOptions.value
          );
        }
      }
    };

    const init = (data) => {
      setDisabled(data.payment_information.order_state);
      options.value.saleOrderType = data.sales_order_identifier.order_type;
      options.value.saleOrderNumber = data.sales_order_identifier.order_number;
      filterOptions.value = [
        {
          field: "sale_order_number",
          value: data.sales_order_identifier.order_number,
        },
      ];
      getCompanyList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const setDisabled = (value) => {
      switch (value) {
        case SalesOrderState.Pending:
        case SalesOrderState.PendingPayment:
        case SalesOrderState.Refunded:
        case SalesOrderState.Canceled:
        case SalesOrderState.OnHold:
          // case SalesOrderState.Closed:
          isDisabledAdd.value = true;
          break;
        default:
          isDisabledAdd.value = false;
          break;
      }
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      getCompanyList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      getCompanyList(
        currentPage.value,
        pagesize,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const renew = () => {
      getCompanyList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getCompanyList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const statusClass = computed(() => {
      return (status: NumberOrString) => {
        return getFulfillmentStatus(status);
      };
    });

    const cancelItem = (id) => {
      cancelId.value = id;
    };

    return {
      t,
      formatDate,
      formatDateTime,
      route,
      loading,
      isDisabledAdd,
      tableHeader,
      options,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      cancelId,
      getCompanyList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      statusClass,
      renew,
      cancelItem,
    };
  },
});
