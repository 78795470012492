import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25c172e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "card mb-5 mb-xl-10",
  id: "kt_profile_details_view"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "fw-bolder m-0" }
const _hoisted_5 = { class: "card-title m-0" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "svg-icon svg-icon-2" }
const _hoisted_8 = { class: "card-body p-9" }
const _hoisted_9 = { class: "fs-7 text-gray-400 text-uppercase" }
const _hoisted_10 = { style: {"min-width":"220px"} }
const _hoisted_11 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MBDatatable = _resolveComponent("MBDatatable")!
  const _component_addFulfillmentOverview = _resolveComponent("addFulfillmentOverview")!
  const _directive_auth = _resolveDirective("auth")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.t("fulfillmentOrder.fulfillmentOverview")), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _withDirectives((_openBlock(), _createElementBlock("button", {
          type: "button",
          class: "btn btn-primary",
          "data-bs-toggle": "modal",
          "data-bs-target": "#modal_fulfillmen_overview",
          disabled: _ctx.isDisabledAdd
        }, [
          _createElementVNode("span", _hoisted_7, [
            _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr075.svg" })
          ]),
          _createTextVNode(" " + _toDisplayString(_ctx.t("common.add")), 1)
        ], 8, _hoisted_6)), [
          [_directive_auth, { auth: ['update'] }]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_MBDatatable, {
        loading: _ctx.loading,
        "table-data": _ctx.tableData,
        "table-header": _ctx.tableHeader,
        "enable-items-per-page-dropdown": true,
        "current-page": _ctx.currentPage,
        total: _ctx.total,
        "rows-per-page": _ctx.pageSize,
        order: "desc",
        onCurrentChange: _ctx.onCurrentPageChange,
        onItemsPerPageChange: _ctx.onRowsPerPageChange,
        onSort: _ctx.onColumnSort
      }, {
        "cell-fulfillment_order_number": _withCtx(({ row: item }) => [
          _createVNode(_component_router_link, {
            class: "text-gray-800 text-hover-primary mb-1",
            tag: "a",
            target: "_blank",
            to: 
              '/mb-order-routing/fulfillment-orders/' +
              item.id +
              '/' +
              item.fulfillment_order_number +
              '/overview'
            
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.fulfillment_order_number), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ]),
        "cell-created_at": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(_ctx.formatDate(item.created_at)), 1)
        ]),
        "cell-status": _withCtx(({ row: item }) => [
          _createElementVNode("span", {
            class: _normalizeClass(["badge uppercase", _ctx.statusClass(item.status)])
          }, _toDisplayString(item.__show.status), 3)
        ]),
        "cell-sale_order_number": _withCtx(({ row: item }) => [
          _createElementVNode("div", null, _toDisplayString(item.sale_order_number), 1),
          _createElementVNode("div", _hoisted_9, _toDisplayString(item.order_item_position), 1)
        ]),
        "cell-estimated_delay_days": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.estimated_delay_days), 1)
        ]),
        "cell-merchant": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.__show.merchant_name), 1)
        ]),
        "cell-channel": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.__show.channel_name), 1)
        ]),
        "cell-actions": _withCtx(({ row: item }) => [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_router_link, {
              tag: "a",
              target: "_blank",
              to: 
                '/mb-order-routing/fulfillment-orders/' +
                item.id +
                '/' +
                item.fulfillment_order_number +
                '/overview'
              ,
              class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_11, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/art/art005.svg" })
                ])
              ]),
              _: 2
            }, 1032, ["to"])
          ])
        ]),
        _: 1
      }, 8, ["loading", "table-data", "table-header", "current-page", "total", "rows-per-page", "onCurrentChange", "onItemsPerPageChange", "onSort"])
    ]),
    _createVNode(_component_addFulfillmentOverview, {
      sale_order_type: String(_ctx.options.saleOrderType),
      order_number: String(_ctx.options.saleOrderNumber),
      onUpdateList: _ctx.renew
    }, null, 8, ["sale_order_type", "order_number", "onUpdateList"])
  ]))
}